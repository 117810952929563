<template>
  <v-dialog v-model="dialog" max-width="1200" persistent>
    <template v-slot:activator="{ on }">
      <v-btn text small color="grey" v-on="on" class="ma-2" @click="createEditingProduct">
        <v-icon small left>mdi-pencil</v-icon>
        Редактировать
      </v-btn>
    </template>
    <v-card class="product-edit-card px-4" outlined>
      <v-form ref="productForm" @submit.prevent="checkInput">
        <v-card-title>
          <span>Изменить продукт</span>
        </v-card-title>
        <div v-if="editingProduct">
          <v-card-text>
            <span class="font-weight-medium">Изображение</span>
            <div v-if="productPicture" class="imgContainer">
              <img :src="url() + productPicture.path" alt="Изображение" />
            </div>
            <div v-else class="imgContainer">
              <img :src="url() + product.productPicture.path" alt="Изображение" />
            </div>
            <span class="font-weight-medium">Артикул</span>
            <v-text-field v-model="editingProduct.article" hide-details="auto" class="mt-1 mb-3" single-line outlined dense disabled="disabled"></v-text-field>
            <span class="font-weight-medium">Количество по умолчанию</span>
            <v-text-field v-model="editingProduct.default_count" type="number" class="mt-1" single-line outlined dense />
            <span class="font-weight-medium">Название в чеке</span>
            <v-text-field
              v-model="editingProduct.receipt_name"
              :rules="notEmptyRules"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
            ></v-text-field>
            <span class="font-weight-medium">Тип товара</span>
            <v-select
              v-model="editingProduct.type"
              :rules="taxRules"
              :items="typeList()"
              item-value="value"
              item-text="name"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-select>
            <span class="font-weight-medium">Единица измерения</span>
            <v-select
              v-model="editingProduct.receipt_unit"
              :rules="notEmptyRules"
              :items="unitList()"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
            ></v-select>
            <span class="font-weight-medium">Налоговая группа</span>
            <v-select
              v-model="editingProduct.receipt_tax"
              :rules="taxRules"
              :items="taxList()"
              item-value="value"
              item-text="name"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-select>
            <v-col class="font-weight-medium">Локализация</v-col>
            <div>
              <v-row>
                <v-col class=".col" md="1"></v-col>
                <v-col class=".col" md="1"> Язык</v-col>
                <v-col class=".col"> Название</v-col>
              </v-row>
            </div>
            <div v-for="(localization, index) in editingProduct.productLocalizations" :key="`localization${localization.id}`">
              <v-row>
                <v-col class=".col" md="1">
                  <div class="pt-1">
                    <v-btn @click="deleteLocalization(index)"> -</v-btn>
                  </div>
                </v-col>
                <v-col class=".col" md="1">
                  <v-select
                    v-model="localization.lang"
                    :rules="needRules"
                    :items="langList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col class=".col">
                  <v-text-field
                    :value="localization.title"
                    @input="updateLocalization('title', $event, index)"
                    :rules="needRules"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn @click="addLocalization()"> + Добавить</v-btn>
            </div>
            <br />
            <v-col class="font-weight-medium">Цены</v-col>
            <div>
              <v-row>
                <v-col class=".col" md="1" v-if="isDeletePrice"></v-col>
                <v-col class=".col"> Начало</v-col>
                <v-col class=".col"></v-col>
                <v-col class=".col"> Конец</v-col>
                <v-col class=".col"></v-col>
                <v-col class=".col"> Цена</v-col>
              </v-row>
            </div>
            <div v-for="(price, index) in editingProduct.productPrices" :key="`price${price.id}`">
              <v-row>
                <v-col class=".col" md="1" v-if="isDeletePrice">
                  <div class="pt-1">
                    <v-btn @click="deletePrice(index)"> -</v-btn>
                  </div>
                </v-col>
                <v-col class=".col">
                  <v-menu
                    v-model="datePicker['start' + index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="price.price_start_date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="needRules"
                        hide-details="auto"
                        class="mt-1 mb-3"
                        single-line
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="price.price_start_date" :first-day-of-week="1" @input="datePicker['start' + index] = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class=".col">
                  <v-text-field
                    :value="price.price_start_time"
                    readonly
                    :rules="needRules"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col class=".col">
                  <v-menu
                    v-model="datePicker['end' + index]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="price.price_end_date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="needRules"
                        hide-details="auto"
                        class="mt-1 mb-3"
                        single-line
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="price.price_end_date" :first-day-of-week="1" @input="datePicker['end' + index] = false">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class=".col">
                  <v-text-field
                    :value="price.price_end_time"
                    readonly
                    :rules="needRules"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col class=".col">
                  <v-text-field
                    :value="price.price"
                    @input="updatePrice('price', $event, index)"
                    :rules="needRules"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn @click="addPrice()"> + Добавить</v-btn>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn :loading="loading" type="submit" depressed color="primary" class="text-capitalize">Сохранить</v-btn>
          <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
          <ProductDialogUpload />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ProductDialogUpload from './ProductDialogUpload'
import config from '@/config/config'
import ProductWorker from '@/helpers/product/ProductWorker'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isDeletePrice: this.user && this.user.id === 1,
      disabled: true,
      dialog: false,
      loading: false,
      editingProduct: null,
      needRules: [(v) => !!v || 'Обязательное поле'],
      newLangId: 0,
      newPriceId: 0,
      file: {},
      notEmptyRules: [(v) => !!v || 0 || 'Обязательное поле'],
      taxRules: [(v) => v >= 0 || 'Обязательное поле'],
      datePicker: []
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({ productPicture: 'product/productPicture' })
  },
  methods: {
    createEditingProduct() {
      this.editingProduct = JSON.parse(JSON.stringify(this.product))
      for (let i = 0; i < this.editingProduct.productPrices.length; i++) {
        const prod = this.editingProduct.productPrices[i]
        prod.price_start_date = moment(prod.price_start).format('YYYY-MM-DD')
        prod.price_end_date = moment(prod.price_end).format('YYYY-MM-DD')
        prod.price_start_time = moment(prod.price_start).format('HH:mm:ss')
        prod.price_end_time = moment(prod.price_end).format('HH:mm:ss')
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    updateLocalization(key, value, index) {
      this.$set(this.editingProduct.productLocalizations[index], key, value)
    },
    deleteLocalization(index) {
      this.$delete(this.editingProduct.productLocalizations, index)
    },
    addLocalization() {
      this.$set(this.editingProduct.productLocalizations, this.editingProduct.productLocalizations.length, {
        id: `new${this.newLangId++}`,
        lang: ``,
        title: ``
      })
    },
    updatePrice(key, value, i) {
      this.$set(this.editingProduct.productPrices[i], key, value)
    },
    deletePrice(index) {
      this.$delete(this.editingProduct.productPrices, index)
    },
    addPrice() {
      this.$set(this.editingProduct.productPrices, this.editingProduct.productPrices.length, {
        id: `new${this.newPriceId++}`,
        price_start_date: `2021-01-01`,
        price_start_time: `00:00:00`,
        price_end_date: `2021-12-31`,
        price_end_time: `23:59:59`,
        price: `100.00`
      })
    },
    checkInput() {
      if (this.$refs.productForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      if (this.productPicture) {
        this.editingProduct.productPictureId = this.productPicture.id
      }
      for (let i = 0; i < this.editingProduct.productPrices.length; i++) {
        const prod = this.editingProduct.productPrices[i]
        prod.price_start = prod.price_start_date + ' ' + prod.price_start_time
        prod.price_end = prod.price_end_date + ' ' + prod.price_end_time
      }
      this.updateProduct({ product: this.editingProduct, projectId: this.projectId })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.editingProduct = null
      this.resetProductPicture()
    },
    url() {
      return config.pictureBaseUrl
    },
    taxList() {
      return ProductWorker.getTaxList()
    },
    unitList() {
      return ProductWorker.getUnitList()
    },
    langList() {
      return ProductWorker.getLangList()
    },
    typeList() {
      return ProductWorker.getTypeList()
    },
    ...mapActions({ updateProduct: 'product/updateProduct' }),
    ...mapActions('product', ['resetProductPicture'])
  },
  mounted() {
    this.createEditingProduct()
  },
  components: {
    ProductDialogUpload
  }
}
</script>
<style scoped>
.product-edit-card .v-btn {
  font-weight: 400;
}

img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.imgContainer {
  align-self: start;
  width: 90%;
  height: 100px;
}
</style>
