<template>
  <div class="kit-tasks">
    <h1 class="subtitle-1 grey--text">Товары</h1>
    <ProductDialogCreate :user="user" />
    <ProductList :products="products" :user="user" />
  </div>
</template>

<script>
import ProductList from '@/components/product/ProductList'
import ProductDialogCreate from '@/components/product/ProductDialogCreate'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ProductList,
    ProductDialogCreate
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({
      products: 'product/products',
      user: 'auth/user'
    })
  },
  mounted() {
    this.fetchProducts({
      projectId: this.projectId
    })
  },
  methods: {
    ...mapActions('product', ['fetchProducts'])
  },
  watch: {
    file(updatedFile) {
      if (!this.title) {
        this.title = updatedFile.name
      }
    }
  }
}
</script>
