<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card class="product-create-card px-4" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-card-title>Добавить продукт</v-card-title>
          <v-card-text>
            <div v-if="productPicture" class="imgContainer">
              <img :src="url() + productPicture.path" alt="Изображение" />
            </div>
            <span class="font-weight-medium">Артикул</span>
            <v-text-field
              v-model="product.article"
              :rules="articleRules"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-text-field>
            <span class="font-weight-medium">Количество по умолчанию</span>
            <v-text-field
              v-model="product.defaultCount"
              :rules="defaultCountRules"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-text-field>
            <span class="font-weight-medium">Название в чеке</span>
            <v-text-field v-model="product.receipt_name" :rules="notEmptyRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense></v-text-field>
            <span class="font-weight-medium">Тип товара</span>
            <v-select
              v-model="product.type"
              :rules="taxRules"
              :items="typeList()"
              item-value="value"
              item-text="name"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-select>
            <span class="font-weight-medium">Единица измерения</span>
            <v-select
              v-model="product.receipt_unit"
              :rules="notEmptyRules"
              :items="unitList()"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
            ></v-select>
            <span class="font-weight-medium">Налоговая группа</span>
            <v-select
              v-model="product.receipt_tax"
              :rules="taxRules"
              :items="taxList()"
              item-value="value"
              item-text="name"
              hide-details="auto"
              class="mt-1 mb-3"
              single-line
              outlined
              dense
              type="number"
            ></v-select>
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn :loading="loading" type="submit" depressed color="primary" class="text-capitalize"> Добавить </v-btn>
            <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
            <ProductDialogUpload />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductDialogUpload from './ProductDialogUpload'
import config from '@/config/config'
import ProductWorker from '@/helpers/product/ProductWorker'
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      product: this.createProductStub(),
      articleRules: [(v) => !!v || 'Обязательное поле, только числа'],
      defaultCountRules: [(v) => !!v || 'Обязательное поле, только числа'],
      notEmptyRules: [(v) => !!v || 0 || 'Обязательное поле'],
      taxRules: [(v) => v >= 0 || 'Обязательное поле']
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    ...mapGetters({ productPicture: 'product/productPicture' })
  },
  methods: {
    createProductStub() {
      this.resetProductPicture()
      return {
        productPictureId: 1,
        article: '',
        defaultCount: 0
      }
    },
    checkInput() {
      if (this.$refs.productForm.validate()) {
        this.loading = true
        this.submitData()
      }
    },
    submitData() {
      if (this.productPicture) {
        this.product.productPictureId = this.productPicture.id
      }
      this.createProduct({ product: this.product, projectId: this.projectId })
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.$refs.productForm.reset()
      this.product = this.createProductStub()
      this.resetProductPicture()
    },
    url() {
      return config.pictureBaseUrl
    },
    taxName(tax) {
      return ProductWorker.getTaxGroupName(tax)
    },
    taxList() {
      return ProductWorker.getTaxList()
    },
    unitList() {
      return ProductWorker.getUnitList()
    },
    typeList() {
      return ProductWorker.getTypeList()
    },
    ...mapActions('product', ['createProduct']),
    ...mapActions('product', ['resetProductPicture'])
  },
  components: {
    ProductDialogUpload
  }
}
</script>
<style>
.product-create-card .v-btn {
  font-weight: 400;
}
img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.imgContainer {
  width: 90%;
  height: 100px;
}
</style>
