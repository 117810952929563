const TAX = [
  {
    value: 0,
    name: 'БезНДС'
  },
  {
    value: 1,
    name: 'НДС 10%'
  },
  {
    value: 2,
    name: 'НДС 20%'
  },
  {
    value: 3,
    name: '10/110'
  },
  {
    value: 4,
    name: '20/120'
  },
  {
    value: 5,
    name: 'НДС не облагается'
  }
]
const UNITS = ['шт', 'уп']
const LOCALIZATIONS = ['ru', 'en', 'de', 'fi', 'it']
const TYPES = [
  {
    value: 2,
    name: 'Сувенир'
  },
  {
    value: 8,
    name: 'Еда'
  }
]

export default {
  getTaxGroupName(taxGroup) {
    for (let i = 0; i < TAX.length; i++) {
      if (TAX[i].value === taxGroup) {
        return TAX[i].name
      }
    }
    return 'Код: ' + taxGroup
  },
  getTaxList() {
    return TAX
  },
  getUnitList() {
    return UNITS
  },
  getLangList() {
    return LOCALIZATIONS
  },
  getTypeList() {
    return TYPES
  }
}
