<template>
  <v-expansion-panels hover>
    <ProductListItem v-for="product in products" :key="product.id" :product="product" :user="user" />
  </v-expansion-panels>
</template>

<script>
import ProductListItem from './ProductListItem'

export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    ProductListItem
  }
}
</script>
